import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  detectBrowserLanguage,
  UserSettingsContext,
  useTranslationLoader,
  Spinner,
  SessionContext,
  AppSettingsContext,
} from 'taulia-ui';
import { usePasswordEncryptionFetch } from '../hooks';
import LoginError from '../components/LoginError';

function UserSettingsContextProvider({ children, logger }) {
  const { translationHash } = useContext(SessionContext);
  const {
    locale: profileLocale,
    captchaSiteKey,
    activationLinkUrl,
    defaultLoginRedirectUrl,
  } = useContext(AppSettingsContext);
  const [locale, setLocale] = useState();
  const [optional2faSetup, setOptional2faSetup] = useState(null);
  const [optional2faRedirectUrl, setOptional2faRedirectUrl] = useState(null);
  const redirectUser = useCallback(
    redirectUrl => {
      logger.log(`Redirecting logged in user to [${redirectUrl}]`);
      window.location = redirectUrl;
    },
    [logger]
  );

  useEffect(() => {
    if (profileLocale) {
      setLocale(profileLocale.replace('_', '-'));
    } else {
      const browserLanguage = detectBrowserLanguage();
      setLocale(browserLanguage.replace('_', '-'));
    }
  }, [profileLocale]);

  const {
    rsaEncrypt,
    refetchConfig,
    refetchingConfig,
    loading: passwordEncryptionConfigLoading,
    error: passwordEncryptionConfigError,
  } = usePasswordEncryptionFetch();

  const { complete: translationsLoaded, error: translationLoadingError } =
    useTranslationLoader(
      locale,
      translationHash,
      '',
      '/api/i18n/translations',
      'app-login'
    );

  const contextValue = useMemo(
    () => ({
      activationLinkUrl,
      defaultLoginRedirectUrl,
      logger,
      captchaSiteKey,
      redirectUser,
      rsaEncrypt,
      refetchConfig,
      optional2faSetup,
      setOptional2faSetup,
      optional2faRedirectUrl,
      setOptional2faRedirectUrl,
    }),
    [
      activationLinkUrl,
      defaultLoginRedirectUrl,
      logger,
      captchaSiteKey,
      redirectUser,
      rsaEncrypt,
      refetchConfig,
      optional2faSetup,
      setOptional2faSetup,
      optional2faRedirectUrl,
      setOptional2faRedirectUrl,
    ]
  );

  if (passwordEncryptionConfigError || translationLoadingError) {
    return <LoginError />;
  }

  if (
    !translationsLoaded ||
    (passwordEncryptionConfigLoading && !refetchingConfig)
  ) {
    return <Spinner hasDelay showBackground={false} />;
  }

  return (
    <UserSettingsContext.Provider value={contextValue}>
      {children}
    </UserSettingsContext.Provider>
  );
}

export default UserSettingsContextProvider;

UserSettingsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  logger: PropTypes.shape().isRequired,
};
